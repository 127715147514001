<template>
    <div id="content" class="design-system">
        <div class="bloque">
            <Input gType="texteditor" v-model="textEditorContent" :gOptions="{ type: 'inline', actions: 'text' }" gState="warning"></Input>

            <Input gType="texteditor" v-model="textEditorContent" gPlaceholder="Escribe el titulo..." :gOptions="{ type: 'inline', limit: 250 }"></Input>

            <Input gType="texteditor" v-model="textEditorContentFull" gLabel="Escribe aqui la descripcion:" :gOptions="{}"></Input>

            <Input gType="texteditor" v-model="textEditorContent" :gOptions="{ actions: 'text' }"></Input>

            vista padre -> {{ textEditorContent }}
            <Input gType="texteditor" v-model="textEditorContent" :gOptions="{ actions: ['text', 'italic', 'clear'] }"></Input>
        </div>
        <div class="bloque">
            <div
                class="image"
                :style="{
                    backgroundImage: 'url(' + resultImage + ')'
                }"
            ></div>
            <Button bType="save" customClass="btn-neutro" :bCallback="openGaleryIcons" bLabel="Galería de imagenes"></Button>
            <Button bType="delete" :bCallback="openPopupError" bLabel="Popup type delete"></Button>
        </div>
        <div class="bloque">
            <h1>TOOLTIPS (ejemplos)</h1>
            <br />
            <Button bType="save" customClass="btn-neutro" v-tooltip="{ content: tooltipText }"></Button>
            <Button bType="save" customClass="btn-neutro" v-tooltip="{ content: tooltipText, classes: 'tooltip-info' }"></Button>
            <Button bType="save" customClass="btn-neutro" v-tooltip="{ content: tooltipText, classes: 'tooltip-success' }"></Button>
            <Button bType="save" customClass="btn-neutro" v-tooltip="{ content: tooltipText, classes: 'tooltip-progress' }"></Button>

            <Button bType="save" customClass="btn-neutro" v-tooltip="{ content: tooltipText, classes: 'tooltip-warning' }"></Button>
            <Button bType="save" customClass="btn-neutro" v-tooltip="{ content: tooltipText, classes: 'tooltip-error' }"></Button>
            <Button bType="save" customClass="btn-neutro" v-tooltip="{ content: tooltipText, classes: 'tooltip-neutro', show: true }"></Button>
            <Button bType="save" customClass="btn-neutro" v-tooltip="{ content: tooltipText, classes: 'tooltip-secondary' }"></Button>
        </div>

        <div class="bloque" v-tooltip="'Efectiviwonder, falta poner aqui todos los tipos de button etc etc xddd'">
            <h1>BUTTONS</h1>
            <br />

            <Button bType="save" customClass=""></Button>
            <Button bType="save" customClass="btn-neutro"></Button>
            <Button bType="save" customClass="btn-secondary"></Button>
            <Button bType="save" customClass="btn-action"></Button>
            <Button bType="save" customClass="btn-action" :isLoading="true" bLabel="Loading"></Button>
            <Button bType="save" customClass="btn-action" bLabel="Con action" :bCallback="testAction" :isLoading="executing"></Button>
        </div>

        <div class="bloque">
            <h1>TAGS</h1>
            <br />

            <Tags :tags="items" :nameTag="'name'" value="id" :limit="false"></Tags>
        </div>

        <div class="bloque botones">
            <h1>SNACKBARS (ejemplos)</h1>
            <br />
            <button class="btn" @click="$snackbar.main({ message: 'dasd asd asd' })">snack main</button>
            <button class="btn" @click="$snackbar.info({ message: 'dasd asd asd' })">snack info</button>
            <button class="btn" @click="$snackbar.success({ message: 'dasd asd asd' })">snack success</button>
            <button class="btn" @click="$snackbar.progress({ message: 'dasd asd asd' })">snack progress</button>
            <button class="btn" @click="$snackbar.warning({ message: 'dasd asd asd' })">snack warning</button>
            <button class="btn" @click="$snackbar.error({ message: 'dasd asd asd' })">snack error</button>
        </div>

        <div class="bloque">
            <h1>INPUTS</h1>
            <br />

            <h2>Tipos</h2>
            <br />
            <p>Vista padre -> {{ inputText }}</p>
            <Input @blur="blurMethod" :gLabel="$t('popup.issue_alert.title')" v-model="inputText"></Input>
            <br />

            <p>Vista padre -> {{ inputNumber }}</p>
            <Input @blur="blurMethod" gLabel="Tipo NUMBER" gType="number" v-model="inputNumber" :gOptions="{ max: 20, min: -50, step: 5 }"></Input>
            <br />

            <p>Vista padre -> {{ inputDate }}</p>
            <Input @blur="blurMethod" gLabel="Tipo DATE" gType="date" v-model="inputDate"></Input>
            <br />

            <p>Vista padre -> {{ inputTime }}</p>
            <Input @blur="blurMethod" gLabel="Tipo TIME" gType="time" v-model="inputTime"></Input>
            <br />

            <p>Vista padre -> {{ inputDateTime }}</p>
            <Input @blur="blurMethod" gLabel="Tipo DATETIME" gType="datetime" v-model="inputDateTime"></Input>
            <br />

            <p>Vista padre -> {{ inputSelect }}</p>
            <Input @blur="blurMethod" gLabel="Tipo SELECT" gType="select" v-model="inputSelect" :gItems="items" :gOptions="{ multiple: true }"></Input>
            <br />

            <p>Vista padre -> {{ inputAutocomplete }}</p>
            <Input @blur="blurMethod" gLabel="Tipo AUTOCOMPLETE" gType="autocomplete" v-model="inputAutocomplete" :autocompleteValue="inputAutocomplete" :gOptions="{ multiple: true, newInput: true }" :gItems="Object.values(items)"></Input>
            <br />

            <p>Vista padre -> {{ inputTextarea }}</p>
            <Input @blur="blurMethod" gLabel="Tipo textarea" gType="textarea" v-model="inputTextarea" :gItems="items"></Input>
            <br />
            <p>Vista padre -> {{ inputComboBox }}</p>
            <Input @blur="blurMethod" gLabel="Tipo comboBox" :gOptions="{ multiple: true, mandatory: true }" gState="error" gType="combobox" v-model="inputComboBox" :gItems="items"></Input>
            <br />

            <hr />

            <h2>Estados</h2>
            <br />
            <Input gLabel="Nombre normal" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre con error" gState="error" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre con warning" gState="warning" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre done donete" gState="done" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre bloqueado" gState="disabled" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre destacado OwO" gState="highlight" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <hr />

            <h2>Tamaños</h2>
            <br />
            <Input gLabel="Nombre xs" gSize="xs" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre s" gSize="s" gMessage="lorem*5 ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre sm" gSize="sm" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre m" gSize="m" gMessage="lorem*5 ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre ml" gSize="ml" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre l" gSize="l" gMessage="lorem*5 ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre xl" gSize="xl" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <hr />

            <h2>Formatos</h2>
            <br />
            <Input></Input>
            <Input :gOptions="{ mandatory: true }"></Input>
            <Input gHelp="Hola soy un texto de ayuda xDxD"></Input>
            <hr />

            <h2>Otras clases</h2>
            <br />
            <hr />

            <Input gType="datetime" :gMessage="{ default: 'Debes rellenarlo.', error: 'BAIA BAIA' }" gState="error"></Input>
            <Input gLabel="Nombre y apellidos" gType="text" gHelp="Aqui tienes que especificar..." gMessage="Mínimo 8 caracteres"></Input>
            <Input gLabel="Nombre y apellidos" gState="error" :gOptions="{ mandatory: true }" gSize="xs"></Input>
            <Input gLabel="Nombre y apellidos" gState="error" :gOptions="{ mandatory: true }" gHelp="hola soy el texto de ayuda xDxd" gMessage="Hola soy el msg que informa 2fast sobre qué datos se esperan" gSize="xs"></Input>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'DesignSystem',
    data() {
        return {
            items: [
                { id: '111', name: 'Hola' },
                { id: '222', name: 'Adios' },
                { id: '444', name: 'Adeu', tooltipMsg: 'Vengaa Adeu Andreu' },
                { id: '333', name: 'Ciao', tooltipMsg: 'Epaaaaa toma error ahi', tooltipClass: 'tooltip-error' },
                { id: '555', name: 'Holi', tooltipMsg: 'OwO' }
            ],
            tooltipText: 'Hola soy un texto de prueba para el tooltip. K pasa xdxd <br> <h2>aber esos titulos xdxd</h2> se ve guay eh jajjaj<b>xd</b>',
            inputText: '',
            inputNumber: '',
            inputDate: '',
            inputTime: '',
            inputDateTime: '',
            inputSelect: '',
            inputAutocomplete: '',
            inputTextarea: '',
            inputComboBox: [],
            executing: false,
            resultImage: false,
            textEditorContent: '',
            textEditorContentFull: `
                    <h2>
                      Hi there,
                    </h2>
                    <p>
                      this is a <em>basic</em> example of <strong>tiptap</strong>. Sure, there are all kind of <u>basic text styles</u> you’d probably expect from a text editor. But wait until you see the lists:
                    </p>
                    <ul>
                      <li>
                        That’s a bullet list with one …
                      </li>
                      <li>
                        … or two list items.
                      </li>
                    </ul>
                    <p>
                      Isn’t that great? And all of that is editable. But wait, there’s more. Let’s try a code block:
                    </p>
                    
                    <p>
                      I know, I know, this is impressive. It’s only the tip of the iceberg though. Give it a try and click a little bit around. Don’t forget to check the other examples too.
                    </p>
                    
                  `
        }
    },
    computed: {
        galleryIcons() {
            var items = this.$store.getters['label/getGalleryImages']()
            return items
        }
    },
    methods: {
        blurMethod() {},

        testAction() {
            if (!this.executing) {
                this.executing = true

                setTimeout(() => {
                    this.executing = false
                }, 2000)
            }
        },
        image(image) {
            this.resultImage = image
        },
        load() {
            var items = this.$store.getters['label/getGalleryImages']()
            if (Object.keys(items).length == 0) {
                this.$store.dispatch('label/loadGalleryImages', {}).then(function (response) {})
            }
        },

        openGaleryIcons() {
            var self = this
            self.$popup.galeryIcons({
                title: this.$t('product.gallery_icons.title'),
                image: self.resultImage,
                textSave: this.$t('button.save'),
                inputVal: 'link',
                data: this.galleryIcons,
                textCancel: this.$t('button.cancel'),
                callSave: function () {
                    self.image(this.image)
                    self.$popup.close()
                },
                callCancel: function () {
                    self.$popup.close()
                }
            })
        },
        openPopupError() {
            var self = this
            self.$popup.delete({ requireTyping: true, callSave: () => {} })
        }
    },
    created() {
        this.load()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.design-system {
    padding: 60px;

    .botones .btn {
        background-color: $color-neutral-200;
        color: $color-black;
    }

    .bloque {
        @include border-radius(6px);
        background-color: $color-neutral-200;
        margin-bottom: 40px;
        padding: 20px 40px;
    }
    .image {
        width: 80px;
        height: 80px;
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-right: 10px;
    }
}
</style>
